import * as React from 'react'
import BlogPostOld from "../../components/blog-post-old";
import image from "../../images/blog41/image2.png"
import image2 from "../../images/blog41/image5.png"
import image3 from "../../images/blog41/image1.jpg"
import image4 from "../../images/blog41/image4.png"
import image5 from "../../images/blog41/image3.jpg"

export default function ACLInjuriesPartThree(){
    return(
        <BlogPostOld
            src={image}
            title={"Knee Pain Should NOT Keep You from Running"}
            summary={"Have you been told you need to ditch running because of your knee pain? What if I told you that you should not listen to this advice? You’re probably thinking, “sure that sounds nice but every time I run, it hurts and I have tried everything”."}
            date={"Jun. 13, 2024"}
            author={"Dr. Donald Mull, DC"}
            InStyle={"object-bottom"}

        >
            <>
  <p>
    Have you been told you need to ditch running because of your knee pain? What
    if I told you that you should not listen to this advice? You’re probably
    thinking, “sure that sounds nice but every time I run, it hurts and I have
    tried everything”.&nbsp;
  </p>
  <p>
    This is a conversation and scenario we see far too often when we meet with
    people and get the opportunity to help them get back to running. Many people
    will tell us they have tried physical therapy but “it didn’t work for them”.
    Some will say “I was given a sheet of exercises that I could’ve just found
    on the internet so I went twice and stopped going” or “the therapist was
    really nice but I was getting the same exercises as everyone else in the
    room and it didn’t seem very challenging” or “I liked the staff but it
    seemed like a med spa and felt good but I still had the same problems so I
    stopped after a couple sessions”.
  </p>
  <p>
    These are examples we hear on a daily basis. Let’s talk about how a catered
    game plan and exercises that are challenging enough to simulate the demands
    of running is a GAME CHANGER for most people we see.&nbsp;
  </p>
  <p>
    When we meet with someone for the first time, we dedicate 90 minutes of time
    completely one on one with a doctorate level clinician. We do this so we can
    get to know each and every aspect of your story so we can sift out what
    factors may be contributing to the sensations and limitations you may be
    experiencing.&nbsp;
  </p>
  <img src={image2} alt={""}/>
  <p>
    The unique characteristics of your story along with what we find together
    when we bring you through a workout give us much more usable information
    than a medical diagnosis of meniscus degeneration, osteoarthritis,
    patellofemoral pain syndrome, etc. How much running and other activities you
    are currently able to handle as well as what your current goals are should
    dictate your rehab program. This is what is trainable and what is
    changeable. The reality is, once we have made sure the pain you are
    experiencing is not in need of surgery or other medical attention, we no
    longer need to focus on the diagnosis. We need to focus on how to get you to
    your goals.&nbsp;
  </p>
  <p>
    For example, if you have experienced your problem pretty consistently after
    30 minutes or so into the run you don’t have a knee problem, you have a
    muscle endurance problem. If you have pain going downhill or down stairs,
    you do not have a knee problem, you have a force absorption problem. If you
    have knee pain when you bend your knee in a weighted squat but no pain when
    you take the weight away, you do not have a knee problem, you have
    sensitivity to load. If you have knee pain when you run three days in a row
    but not when you run once in a week, you do not have a knee problem, you
    have a load management problem.&nbsp;
  </p>
  <p>
    We are pretty confident saying that running is not bad for your knees
    because well that is what the research has told us. For example, if you take
    three groups of individuals, the first being sedentary, the second being
    recreational runners and the third being elite runners clocking over 50
    miles a week, who do you think has the most arthritis? Interestingly, those
    who have the least arthritis in their knees by far are the recreational
    runners(1). Essentially the ones who run at moderate levels. Because the
    impact and cyclical nature of running encourages a response for the body to
    lubricate the joint and encourage resilience of the cartilage and articular
    surfaces of the joint. From this perspective running is actually
    PROTECTIVE.&nbsp;
  </p>
  <img src={image3} alt={""}/>
  <p>
    With that being said, we do believe that if someone is experiencing adverse
    effects from running we should make sure they are preparing their body for
    the demands of this activity. In order to do so, we need to realize that the
    demands of running are quite high. Running is essentially jumping from one
    foot to another for thousands of reps at a time. Each time the foot strikes
    the ground the leg muscles take on anywhere from 2 to 8 times your
    bodyweight in forces(2)!
  </p>
  <img src={image4} alt={""}/>
  <p>
    This piece of information is very crucial in my humble opinion. This is why
    easy “rehab” exercises with light bands are not enough to prepare the body
    to be exposed to these amounts of forces. Not saying these lower intensity
    movements that focus on body position, mobility or balance are useless, they
    are just a warmup for what runners should be doing in their rehab and
    training.&nbsp;
  </p>
  <p>
    The way we can prepare the body for running is with HEAVY resistance and
    some plyometrics. When runners introduce this type of training there is a
    positive effect on running performance and the longer they stick to the
    program the better the performance(3). If increasing performance is not
    enough of a reason to implement strength training to your regular routine,
    it also decreases the risk of future injuries once you do return to your
    full running routine (4,5).&nbsp;
  </p>
  <img src={image5} alt={""}/>
  <p>
    When looking at the demands of running and seeing how the body can adapt and
    respond to lifting weights and jump training, this is precisely what one’s
    rehab should look like. This may take time to build into but it should be
    continuously progressing until it looks like full resistance training and
    jump training that supplements your running routine.&nbsp;
  </p>
  <p>
    Keep in mind that this process takes time (much longer than a few sessions).
    You should be allocating 12 weeks plus of training sessions to allow the
    body to adapt to the stressors you put on it. This is why we ensure guidance
    during this whole process, whether we work with you very frequently or not
    depends on how much guidance we agree you may need. With that being said, we
    will always be in your corner to help guide you through your training and
    your journey back to hitting the pavement WITHOUT limitations and maybe even
    better than ever!
  </p>
  <p>Resources</p>
  <ol>
    <li>
      Alentorn-Geli E, Samuelsson K, Musahl V, Green CL, Bhandari M, Karlsson J.
      The Association of Recreational and Competitive Running With Hip and Knee
      Osteoarthritis: A Systematic Review and Meta-analysis. J Orthop Sports
      Phys Ther. 2017 Jun;47(6):373-390. doi: 10.2519/jospt.2017.7137. Epub 2017
      May 13. PMID: 28504066.
    </li>
    <li>
      Dorn TW, Schache AG, Pandy MG. Muscular strategy shift in human running:
      dependence of running speed on hip and ankle muscle performance. J Exp
      Biol. 2012 Jun 1;215(Pt 11):1944-56. doi: 10.1242/jeb.064527. Erratum in:
      J Exp Biol. 2012 Jul 1;215(Pt 13):2347. PMID: 22573774.
    </li>
    <li>
      Eihara Y, Takao K, Sugiyama T, Maeo S, Terada M, Kanehisa H, Isaka T.
      Heavy Resistance Training Versus Plyometric Training for Improving Running
      Economy and Running Time Trial Performance: A Systematic Review and
      Meta-analysis. Sports Med Open. 2022 Nov 12;8(1):138. doi:
      10.1186/s40798-022-00511-1. PMID: 36370207; PMCID: PMC9653533.
    </li>
    <li>
      Lauersen JB, Bertelsen DM, Andersen LB. The effectiveness of exercise
      interventions to prevent sports injuries: a systematic review and
      meta-analysis of randomised controlled trials. Br J Sports Med. 2014
      Jun;48(11):871-7. doi: 10.1136/bjsports-2013-092538. Epub 2013 Oct 7.
      PMID: 24100287.
    </li>
    <li>
      Hameed I, Farooq N, Haq A, Aimen I, Shanley J. Role of strengthening
      exercises in management and prevention of overuse sports injuries of lower
      extremity: a systematic review. J Sports Med Phys Fitness. 2024 Mar 12.
      doi: 10.23736/S0022-4707.23.15470-3. Epub ahead of print. PMID: 38470015.
    </li>
  </ol>
</>






        </BlogPostOld>

    )
}